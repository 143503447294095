@import '../variables';
@import '../mixins';

@keyframes ball-beat {
  50% {
    opacity: 0.2;
    transform: scale(0.75);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.ball-beat {

  > div {
    @include balls();
    @include global-animation();

    display: inline-block;
    animation: ball-beat 0.7s 0s infinite linear;

    &:nth-child(2n-1) {
      animation-delay: -0.35s !important;
    }
  }
}
