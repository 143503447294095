@import '../variables';
@import '../mixins';

@keyframes rotate {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(0.6);
  }
  100% {
   transform: rotate(360deg) scale(1);
 }
}

.ball-clip-rotate-multiple {
  position: relative;

  > div {
    @include global-animation();

    position: absolute;
    left: -20px;
    top: -20px;
    border: 2px solid $primary-color;
    border-bottom-color: transparent;
    border-top-color: transparent;
    border-radius: 100%;
    height: 35px;
    width: 35px;
    animation: rotate 1s 0s ease-in-out infinite;

    &:last-child {
      display: inline-block;
      top: -10px;
      left: -10px;
      width: 15px;
      height: 15px;
      animation-duration: 0.5s;
      border-color: $primary-color transparent $primary-color transparent;
      animation-direction: reverse;
    }
  }
}
