@import '../variables';
@import '../mixins';

$size: 20px;

@keyframes triangle-skew-spin {
  25% {
    transform: perspective(100px) rotateX(180deg) rotateY(0);
  }
  50% {
    transform: perspective(100px) rotateX(180deg) rotateY(180deg);
  }
  75% {
    transform: perspective(100px) rotateX(0) rotateY(180deg);
  }
  100% {
    transform: perspective(100px) rotateX(0) rotateY(0);
  }
}

.triangle-skew-spin {

  > div {
    @include global-animation();

    width: 0;
    height: 0;
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $primary-color;
    animation: triangle-skew-spin 3s 0s cubic-bezier(.09,.57,.49,.9) infinite;
  }
}
