@import '../variables';
@import '../mixins';

@keyframes ball-grid-beat {
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

@mixin ball-grid-beat($n:9) {
  @for $i from 1 through $n {
    > div:nth-child(#{$i}) {
      animation-delay: ((random(100) / 100) - 0.2) + s;
      animation-duration: ((random(100) / 100) + 0.6) + s;
    }
  }

}

.ball-grid-beat {
  @include ball-grid-beat();
  width: ($ball-size * 3) + $margin * 6;

  > div {
    @include balls();
    @include global-animation();

    display: inline-block;
    float: left;
    animation-name: ball-grid-beat;
    animation-iteration-count: infinite;
    animation-delay: 0;
  }
}
